import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { useNavigate } from "react-router-dom";
import {
  getAuth,
  signInWithPhoneNumber,
  RecaptchaVerifier,
  signInWithCredential,
  PhoneAuthProvider,
  setPersistence,
  browserLocalPersistence,
  onAuthStateChanged,
} from "firebase/auth";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import "../App.css";

export default function Login() {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [verificationId, setVerificationId] = useState("");
  const [otp, setOtp] = useState("");
  const [showOTPField, setShowOTPField] = useState(false);
  const [recaptchaVerifier, setRecaptchaVerifier] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const db = getFirestore();

  const navigate = useNavigate();

  useEffect(() => {
    const auth = getAuth();

    // Check if user is already logged in
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        navigate("/account");
      } else {
        // User is not signed in
        setPersistence(auth, browserLocalPersistence)
          .then(() => {
            // Persistence enabled
          })
          .catch((error) => {
            // Handle persistence error
            console.error("Persistence error:", error);
          });

        const verifier = new RecaptchaVerifier(
          "recaptcha-container",
          {
            size: "invisible",
          },
          auth
        );
        setRecaptchaVerifier(verifier);
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  const handleSignIn = async (e) => {
    e.preventDefault();

    const phoneNumberFormatted = `+${phoneNumber}`;

    const docRef = doc(db, "users", phoneNumberFormatted);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      // User exists, sign them in
      signInWithPhoneNumber(getAuth(), phoneNumberFormatted, recaptchaVerifier)
        .then((confirmationResult) => {
          setVerificationId(confirmationResult.verificationId);
          setShowOTPField(true);
          setErrorMessage("");
        })
        .catch((error) => {
          console.error("Phone verification error:", error);
          setErrorMessage("Invalid Phone Number. Please try again.");
        });
    } else {
      // User does not exist, create an error message.
      console.error("User does not exist.");
      setErrorMessage("Account not found. Please sign up.");
      navigate("/signup");
    }
  };

  const handleOTPVerification = (e) => {
    e.preventDefault();

    const auth = getAuth();
    const credential = PhoneAuthProvider.credential(verificationId, otp);
    signInWithCredential(auth, credential)
      .then((userCredential) => {
        // Handle successful OTP verification
        navigate("/account");
        setErrorMessage("");
      })
      .catch((error) => {
        console.error("OTP verification error:", error);
        setErrorMessage("OTP verification error. Please try again.");
      });
  };

  return (
    <div className="App">
      <Header />

      <div className="main-content">
        <div className="flex flex-col items-center min-h-screen px-6 py-12 lg:px-8">
          <div className="sm:w-full sm:max-w-sm">
            <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
              Sign in to your account
            </h2>
          </div>

          <div id="recaptcha-container"></div>

          {errorMessage && (
            <div
              class="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative padding-top:100px"
              role="alert"
            >
              <strong class="font-bold">Error.</strong>
              <span class="block sm:inline"> {errorMessage} </span>
              <span class="absolute top-0 bottom-0 right-0 px-4 py-3"></span>
            </div>
          )}

          <div className="mt-10 sm:w-full sm:max-w-sm">
            {!showOTPField ? (
              <form
                className="space-y-6"
                action="#"
                method="POST"
                onSubmit={handleSignIn}
              >
                <div>
                  <label
                    htmlFor="phone"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Phone number
                  </label>
                  <div className="mt-2">
                    <PhoneInput
                      country={"us"}
                      enableSearch={true}
                      value={phoneNumber}
                      countryCodeEditable={false}
                      onChange={(phone) => setPhoneNumber(phone)}
                      inputStyle={{ paddingLeft: "50px" }}
                      inputProps={{
                        id: "phone",
                        name: "phone",
                        required: true,
                        autoComplete: "tel",
                        className:
                          "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6",
                      }}
                    />
                  </div>
                  <p className="mt-2 text-xs text-gray-500">
                    {" "}
                    SMS message for verification and standard rates may apply.{" "}
                  </p>
                </div>

                <div>
                  <button
                    type="submit"
                    className="flex w-full justify-center rounded-md bg-gray-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    Sign in
                  </button>
                </div>
              </form>
            ) : (
              <form
                className="space-y-6"
                action="#"
                method="POST"
                onSubmit={handleOTPVerification}
              >
                <div>
                  <label
                    htmlFor="otp"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    OTP (One-Time Password)
                  </label>
                  <div className="mt-2">
                    <input
                      id="otp"
                      name="otp"
                      type="text"
                      autoComplete="one-time-code"
                      required
                      value={otp}
                      onChange={(e) => setOtp(e.target.value)}
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>

                  <p className="mt-2 text-xs text-gray-500">
                    {" "}
                    Enter the OTP you recieved in your SMS. Standard rates may
                    apply.
                  </p>
                </div>

                <div>
                  <button
                    type="submit"
                    className="flex w-full justify-center rounded-md bg-gray-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    Verify OTP
                  </button>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}
