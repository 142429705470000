import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AccountHeader from "../components/AccountHeader";
import Footer from "../components/Footer";
import { getAuth } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { getFunctions, httpsCallable } from "firebase/functions";
import {
  onSnapshot,
  collection,
  getFirestore,
  doc,
  getDoc,
  addDoc,
  query,
  where,
  getDocs,
  Timestamp,
  updateDoc,
} from "firebase/firestore";

export default function AccountPage() {
  const [subscription, setSubscription] = useState(null);
  const [daysLeft, setDaysLeft] = useState(0);
  const [membershipType, setMembershipType] = useState("NaN");
  const [parkingSpot, setParkingSpot] = useState(null);
  const [minutesLeft, setMinutesLeft] = useState(0); // UPDATED: State for remaining minutes for Daily Pass
  const [userInformation, setUserInformation] = useState(null);
  const [truckColor, setTruckColor] = useState("");
  const [truckNumber, setTruckNumber] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [autoRenewal, setAutoRenewal] = useState(false); // Default to true
  const [portalLink, setPortalLink] = useState(null);
  const [lotId, setLotId] = useState(null);
  const [spotType, setSpotType] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const purchaseLink = "/";
  const navigate = useNavigate();

  // Fetching User Information
  const fetchUserInformation = async (user) => {
    const db = getFirestore();
    const docRef = doc(db, "users", user.phoneNumber);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      if (docSnap.data().role === "admin") {
        navigate("/admin");
      }
      setUserInformation(docSnap.data());
      setTruckColor(docSnap.data().truckColor);
      setTruckNumber(docSnap.data().truckNumber);
    } else {
      console.error("Document does not exist!");
    }
  };

  // Function to handle User Information Update
  const handleUpdateUserInformation = async () => {
    const db = getFirestore();
    await updateDoc(doc(db, "users", getAuth().currentUser.phoneNumber), {
      truckColor: truckColor,
      truckNumber: truckNumber,
    });
  };

  const handleAutoRenewal = (subscriptionData) => {
    setAutoRenewal(!subscriptionData.cancel_at_period_end);
  };

  const fetchSubscriptionData = async (userRef) => {
    // Fetch the user's subscription data
    const subscriptionQuery = query(
      collection(userRef, "subscriptions"),
      where("status", "==", "active")
    );

    const subscriptionSnapshot = await getDocs(subscriptionQuery);

    if (!subscriptionSnapshot.empty) {
      // If a subscription exists
      subscriptionSnapshot.forEach((subscriptionDoc) => {
        const subscriptionData = subscriptionDoc.data();

        handleAutoRenewal(subscriptionData); // Call function to set auto renewal status
      });
    }
  };

  useEffect(() => {
    const createPortalLink = async () => {
      try {
        setLoading(true);
        const functions = getFunctions();
        const functionRef = httpsCallable(
          functions,
          "ext-firestore-stripe-payments-createPortalLink"
        );

        const result = await functionRef({
          returnUrl: `${window.location.origin}/dashboard-pro/abonnement/`,
          locale: "auto",
        });

        setPortalLink(result.data.url);
      } catch (error) {
        console.error("Error creating portal link:", error);
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    createPortalLink();
  }, []);

  useEffect(() => {
    // Array to hold the unsubscribe functions
    const unsubscribes = [];

    const fetchUserData = () => {
      const auth = getAuth();
      const user = auth.currentUser;

      if (user) {
        const db = getFirestore();
        const userRef = doc(db, "customers", user.uid);

        const fetchParkingSpot = async () => {
          // Fetch 'Gated' and 'Non-Gated' lots separately
          const gatedLotRef = collection(db, "lot/Gated/parkingSpots");
          const nonGatedLotRef = collection(db, "lot/Non-Gated/parkingSpots");

          // Fetch the documents from each collection
          const gatedLotSnapshot = await getDocs(gatedLotRef);
          const nonGatedLotSnapshot = await getDocs(nonGatedLotRef);

          // Concatenate the two sets of documents into a single array
          const allSpotDocs = [
            ...gatedLotSnapshot.docs,
            ...nonGatedLotSnapshot.docs,
          ];

          for (let spotDoc of allSpotDocs) {
            let spotData = spotDoc.data();

            // Find the parking spot linked to this user in the current lot
            if (spotData.user === user.phoneNumber) {
              const parkingEndDate = new Date(spotData.endDate); // Save endDate for later use

              const currentTimestamp = new Date().getTime();
              const remainingDays = Math.ceil(
                (parkingEndDate.getTime() - currentTimestamp) /
                  (1000 * 60 * 60 * 24)
              );

              const remainingMinutes = Math.ceil(
                (parkingEndDate.getTime() - currentTimestamp) / (1000 * 60)
              );

              if (remainingMinutes <= 0) {
                // Skip if endDate has passed
                continue;
              }

              // If spot found and endDate has not passed, update the state variables
              setParkingSpot(spotData.parkingNum);
              setLotId(spotDoc.ref.path.split("/")[1]); // This should give either 'Gated' or 'Non-Gated'
              setSpotType(
                spotData.isFullSize === true ? "Full-Size" : "Trailer/Bobcat"
              ); // Store the spot type

              setMinutesLeft(remainingMinutes);
              setDaysLeft(remainingDays);
              setMembershipType(
                spotData.type === "subscription"
                  ? "1-Month Truck Parking Membership"
                  : "Daily Pass"
              );

              if (spotData.type === "subscription") {
                // If the user has a subscription, fetch the subscription data
                await fetchSubscriptionData(userRef);
              }

              setSubscription({
                current_period_end: Timestamp.fromDate(parkingEndDate),
                items: [{ price: { product: { name: spotData.type } } }],
              });

              console.log("Parking end date: " + parkingEndDate);
              break; // End the loop if spot found
            }
          }
        };

        fetchParkingSpot();

        fetchUserInformation(user);
      } else {
        navigate("/login");
      }
    };

    fetchUserData();

    return () => {
      // Cleanup the subscriptions when unmounting
      unsubscribes.forEach((unsubscribe) => unsubscribe());
      
    };
  }, []);

  useEffect(() => {
    let timer;
    if (isButtonDisabled) {
      timer = setTimeout(() => setIsButtonDisabled(false), 45000); // 45000 milliseconds = 45 seconds
    }
    // Clean up the timer when the component is unmounted or when isButtonDisabled changes
    return () => clearTimeout(timer);
  }, [isButtonDisabled]);


  const renderEndDate = () => {
    if (subscription) {
      const endDate = subscription.current_period_end.toDate();
      return endDate.toLocaleDateString();
    }
    return "End Date";
  };

  const renderTimeLeft = () => {
    if (subscription) {
      const currentTimestamp = new Date().getTime();
      const endDate = subscription.current_period_end.toDate();
      const remainingTime = endDate - currentTimestamp;
      const remainingMinutes = Math.ceil(remainingTime / (1000 * 60));
      const remainingHours = Math.ceil(remainingMinutes / 60);

      if (remainingMinutes <= 0) {
        return "Subscription Expired";
      } else if (remainingMinutes < 60) {
        return "Less than 1 hour left";
      } else if (remainingHours < 24) {
        return `${remainingHours} hours left`;
      } else {
        const daysLeft = Math.ceil(remainingHours / 24);
        return `${daysLeft} days left`;
      }
    } else {
      return "No active passes or subscriptions";
    }
  };

  const handleOpenGate = async () => {
    const db = getFirestore();
    const messagesCollection = collection(db, "messages");
    await addDoc(messagesCollection, {
      to: "+18288189037", // The recipient's phone number
      body: "Open", // The message
    });

    const auth = getAuth();
    const user = auth.currentUser;
    const messageLogCollection = collection(db, "messageLog");
    await addDoc(messageLogCollection, {
      user: user.phoneNumber,
      timestamp: Timestamp.fromDate(new Date()),
    });
    setIsButtonDisabled(true);
  };

  return (
    <div className="App">
      <AccountHeader />
      <div className="main-content mx-auto max-w-2xl px-4 sm:px-6 lg:px-8 mt-8">
        {/* Your content here */}
        {subscription ? (
          <article className="text-center rounded-xl bg-white p-6 ring ring-indigo-50 sm:p-8 lg:p-10">
            <div>
              <h3 className="mt-4 text-xl font-bold">{membershipType}</h3>

              <p className="mt-1 text-base text-gray-700">
                Your subscription will end on {renderEndDate()}.
              </p>

              {membershipType !== "Daily Pass" && (
                <a href={portalLink} target="_blank" rel="noopener noreferrer">
                  <p
                    className={`mt-2 text-base font-mono flex justify-center items-center ${
                      autoRenewal ? "text-green-500" : "text-red-500"
                    }`}
                  >
                    {autoRenewal ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z"
                        />
                      </svg>
                    )}
                    Auto-renewal is {autoRenewal ? "on" : "off"}.
                  </p>
                </a>
              )}

              <div className="mt-4 sm:flex sm:items-center sm:gap-2">
                <div className="flex items-center justify-center gap-1 text-gray-500 mx-auto">
                  <svg
                    className="h-4 w-4"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                    ></path>
                  </svg>
                  <p className="text-sm font-medium">{renderTimeLeft()}</p>
                </div>

                <span className="hidden sm:block" aria-hidden="true">
                  {/* &middot; */}
                </span>
              </div>
            </div>
          </article>
        ) : (
          <article className="text-center rounded-xl bg-white p-6 ring ring-indigo-50 sm:p-8 lg:p-10">
            <div>
              <h3 className="mt-4 text-xl font-bold">
                No Current Subscription
              </h3>
              <p className="mt-1 text-base text-gray-700">
                Subscribe now to access premium features.
              </p>
              <div className="mt-4 text-center">
                <Link
                  to={purchaseLink}
                  className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
                >
                  Purchase Now
                </Link>
              </div>
            </div>
          </article>
        )}

        {subscription && (
          <>
            <article className="text-center rounded-xl bg-white p-6 ring ring-indigo-50 sm:p-8 lg:p-10 mt-4">
              <div>
                <h3 className="mt-4 text-xl font-bold">Parking Spot</h3>
                <p className="mt-2 text-3xl font-bold">{parkingSpot}</p>
                <p className="mt-4">
                  <span className="text-lg font-medium text-gray-500">
                    Lot:
                  </span>{" "}
                  <span className="text-2xl font-semibold">{lotId}</span>
                </p>
                <p className="mt-2">
                  <span className="text-lg font-medium text-gray-500">
                    Spot Type:
                  </span>{" "}
                  <span className="text-2xl font-semibold">{spotType}</span>
                </p>
                {/* Example: Replace with the actual parking spot number */}
                <p className="mt-6 text-red-600 font-medium">
                  Warning: Failure to park in this designated spot may result in
                  fines.
                </p>
              </div>
            </article>

            {lotId === "Gated" && (
              <article className="text-center rounded-xl bg-white p-6 ring ring-indigo-50 sm:p-8 lg:p-10 mt-4">
                <div>
                  <h3 className="mt-4 text-xl font-bold">Gate Access</h3>
                  <button
                    onClick={handleOpenGate}
                    disabled={isButtonDisabled}
                    className={`mt-4 font-bold py-2 px-4 rounded ${
                      isButtonDisabled
                        ? "bg-gray-500 cursor-not-allowed"
                        : "bg-blue-500 hover:bg-blue-600 text-white"
                    }`}
                  >
                    {isButtonDisabled ? "Opening Gate..." : "Open Gate"}
                  </button>
                </div>
              </article>
            )}
          </>
        )}

        {userInformation && (
          <article className="text-left rounded-xl bg-white p-6 ring ring-indigo-50 sm:p-8 lg:p-10 mt-4">
            <div className="flex flex-col items-center">
              {" "}
              {/* Add this container */}
              <h3 className="text-center mb-8 mt-8 text-xl font-bold">
                User Information
              </h3>
              <div className="relative mb-3">
                <label htmlFor="email" className="block text-gray-700">
                  Email
                </label>
                <input
                  type="text"
                  className="opacity-50 rounded-lg border-transparent flex-1 appearance-none border border-indigo-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                  id="user-email"
                  value={userInformation.email}
                  aria-label="User Email"
                  disabled
                />
              </div>
              <div className="relative mb-3">
                <label htmlFor="full-name" className="block text-gray-700">
                  Name
                </label>
                <input
                  type="text"
                  className="opacity-50 rounded-lg border-transparent flex-1 appearance-none border border-indigo-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                  id="user-name"
                  value={userInformation.name}
                  aria-label="User Name"
                  disabled
                />
              </div>
              <div className="relative mb-3">
                <label htmlFor="phone-number" className="block text-gray-700">
                  Phone Number
                </label>
                <input
                  type="text"
                  className="opacity-50 rounded-lg border-transparent flex-1 appearance-none border border-indigo-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                  id="user-phone"
                  value={userInformation.phoneNumber}
                  aria-label="User Phone"
                  disabled
                />
              </div>
              <div className="relative">
                <label htmlFor="truck-color" className="block text-gray-700">
                  Truck Color
                </label>
                <input
                  type="text"
                  id="truck-color"
                  value={truckColor}
                  onChange={(e) => setTruckColor(e.target.value)}
                  className="rounded-lg border-transparent flex-1 appearance-none border border-indigo-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent"
                  placeholder="Your truck color"
                />
              </div>
              <div className="relative">
                <label htmlFor="truck-number" className="block text-gray-700">
                  Truck Number
                </label>
                <input
                  type="text"
                  id="truck-number"
                  value={truckNumber}
                  onChange={(e) => setTruckNumber(e.target.value)}
                  className="rounded-lg border-transparent flex-1 appearance-none border border-indigo-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent"
                  placeholder="Your truck number"
                />
              </div>
              <button
                onClick={handleUpdateUserInformation}
                className="mt-4 bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
              >
                Update
              </button>
            </div>
          </article>
        )}

        <p className="mt-8 text-center text-gray-700 mb-8">
          To view or cancel your subscription, please visit the{" "}
          <Link to={portalLink} target="_blank" className="underline">
            Billing Page
          </Link>
          .
        </p>
      </div>
      <Footer />
    </div>
  );
}
