import React from "react";

const Footer = () => {
  return (
    <footer className="bg-gray-900 shadow-inner">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex flex-wrap justify-center py-6 md:py-8 border-t border-gray-800">
          <div className="flex items-center justify-center mx-6 md:mx-8 text-gray-400 mb-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 md:h-8 md:w-8"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M19 9l-7 7-7-7"
              />
            </svg>
            <span className="ml-3 text-base md:text-lg font-medium text-gray-300">
              A&J Truck Parking
            </span>
          </div>
          <div className="flex items-center justify-center mx-6 md:mx-8 text-gray-400 mb-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 md:h-8 md:w-8"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
            <span className="ml-3 text-base md:text-lg font-medium text-gray-300">
              4589 S Harding St Indianapolis, IN 46217
            </span>
          </div>
          <div className="flex items-center justify-center mx-6 md:mx-8 text-gray-400">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 md:h-8 md:w-8"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8"
              />
            </svg>
            <span className="ml-3 text-base md:text-lg font-medium text-gray-300">
              (509) 688-4391
            </span>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
