import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import 'firebase/auth';
import 'firebase/firestore';
// import reCAPTCHA from "react-google-recaptcha";


const firebaseConfig = {
  apiKey: "AIzaSyB0zKcalnXEL4sTUtY5eLR3ErU7TLu8Ts0",
  authDomain: "aj-truck-parking.firebaseapp.com",
  projectId: "aj-truck-parking",
  storageBucket: "aj-truck-parking.appspot.com",
  messagingSenderId: "584033370777",
  appId: "1:584033370777:web:8e5f0d8915f1e24b0f6e08",
  measurementId: "G-EVTQJH2WR4"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export default app;