import React from "react";
import { getAuth, signOut } from "firebase/auth";

export default function AccountHeader() {
  const handleSignOut = () => {
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        // Sign out successful, redirect user to '/'
        window.location.href = "/";
      })
      .catch((error) => {
        console.error("Sign out error:", error);
      });
  };

  return (
    <nav className="bg-gray-900 shadow-md">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16">
          <div className="flex items-center">
            <button
              className="text-2xl font-bold text-gray-300 hover:text-white transition-colors"
              onClick={() => (window.location.href = "/")}
            >
              A&J Truck Parking {/* Bring user back to the homescreen */}
            </button>
          </div>

          <div className="flex items-center justify-end flex-1">
            <button
              className="bg-gray-900 hover:bg-gray-800 text-gray-300 font-semibold py-2 px-4 border border-gray-700 rounded shadow transition-colors"
              onClick={handleSignOut}
            >
              Sign Out
            </button>
          </div>
        </div>
      </div>
    </nav>
  );
}
