import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";

const Header = () => {
  const navigate = useNavigate();
  const auth = getAuth();
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
    });

    return () => unsubscribe();
  }, [auth]);

  const handleSignUp = () => {
    navigate("/SignUp");
  };

  const handleAccessAccount = () => {
    navigate("/Login");
  };

  return (
    <nav className="bg-gray-900 shadow-md">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          <div className="flex items-center">
            <button
              className="text-2xl font-bold text-gray-300 hover:text-white transition-colors"
              onClick={() => (window.location.href = "/")}
            >
              A&J Truck Parking {/* Bring user back to the homescreen */}
            </button>
          </div>

          <div className="flex items-center justify-end flex-1">
            {user ? (
              <>
                <button
                  className="bg-gray-900 hover:bg-gray-800 text-gray-300 font-semibold py-2 px-4 border border-gray-700 rounded shadow transition-colors mr-4"
                  onClick={handleAccessAccount}
                >
                  Open Gate
                </button>
                {/* <button
                  className="bg-gray-900 hover:bg-gray-800 text-gray-300 font-semibold py-2 px-4 border border-gray-700 rounded shadow transition-colors"
                  onClick={handleSignOut}
                >
                  Sign Out
                </button> */}
              </>
            ) : (
              <button
                className="bg-gray-900 hover:bg-gray-800 text-gray-300 font-semibold py-2 px-4 border border-gray-700 rounded shadow transition-colors"
                onClick={handleSignUp}
              >
                Sign In/Sign Up
              </button>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Header;
