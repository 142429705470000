import React from 'react';
import './../App.css';
import Header from '../components/Header';
import MembershipDetail from '../components/MembershipDetail';
import Footer from '../components/Footer';
import Location from '../components/Location';

function App() {
  return (
    <div className="App">
      <Header />
      <div className="main-content">
        <MembershipDetail />
        <Location />
      </div>
      <Footer />
    </div>
  );
}

export default App;