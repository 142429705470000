import React from "react";

const containerStyles = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  margin: "0 auto",
  marginBottom: "50px",
  width: "90vw", // Adjust the width to your preference
  maxWidth: "600px", // Adjust the maximum width to your preference
};

const mapStyles = {
  height: "300px", // Adjust the height to your preference
  width: "100%",
  marginBottom: "20px",
  borderRadius: "0.5rem",
  boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
};

const infoStyles = {
  background: "#f9f9f9",
  padding: "20px",
  width: "100%",
  borderRadius: "0.5rem",
  boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
};

function Location() {
  return (
    <div style={containerStyles}>
      <div style={infoStyles}>
        <h3 className="text-2xl font-bold tracking-tight text-gray-900 text-black-600">
          A&J Truck Parking
        </h3>
        <p>
          Our truck parking lot is conveniently located across from the Pilot
          Truck Station and next to the Motel 6, providing easy access to
          amenities and services for truckers. Additionally, in the vicinity,
          there is an Indian restaurant called Indian and American Restaurant,
          offering a fusion of Indian and American cuisine to satisfy your taste
          buds.
        </p>
      </div>
      <div style={mapStyles}>
        <iframe
          title="A&J Truck Parking Location"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3069.835159425269!2d-86.1858839227725!3d39.69841009904355!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x886b599ea5886cf7%3A0x92a5ffe105f8947a!2sA%26J%20Truck%20Parking!5e0!3m2!1sen!2sus!4v1684593523037!5m2!1sen!2sus"
          width="100%"
          height="100%"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </div>
  );
}

export default Location;
