import React, { useEffect, useState } from "react";
import { CheckIcon } from "@heroicons/react/solid";
import DailyPass from "./DailyPass";
import { useNavigate } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import {
  onSnapshot,
  collection,
  getFirestore,
  doc,
  addDoc,
  query,
  where,
  getDocs,
  getDoc,
} from "firebase/firestore";
import { loadStripe } from "@stripe/stripe-js";
import Modal from "react-modal";

const gateFeatures = [
  "Private gate access",
  "Private parking spot",
  "24/7 camera security",
  "Online account access",
];

const nonGateFeatures = [
  "Private parking spot",
  "24/7 camera security",
  "Online account access",
];

export default function Example() {
  const history = useNavigate();
  const db = getFirestore();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [spotSize, setSpotSize] = useState(null);
  const [lotType, setLotType] = useState("Gated");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState({
    title: "",
    message: "",
    showSignIn: false,
  });
  const gatedAddress = "4589 S Harding St, Indianapolis, IN 46217";
  const nonGatedAddress = "39.69855681984554, -86.18591412873108";

  const handleLotTypeChange = (type) => {
    setLotType(type);
  };

  const openDirections = (address) => {
    const url = `https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(
      address
    )}`;
    window.open(url, "_blank");
  };

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        setIsLoggedIn(true); // set isLoggedIn to true
      } else {
        setIsLoggedIn(false); // set isLoggedIn to false
      }
    });
    return () => unsubscribe();
  }, [history]);

  // Finds the correct product ID based on the spot size and lot type
  const assignAndHandlePurchaseNow = () => {
    if (!spotSize) {
      setIsModalOpen(true);
      setModalContent({
        title: "Selection Error",
        message: "Please select a spot size before continuing.",
        showSignIn: false,
      });
      return;
    }

    let productID = "null";
    // Find the correct product ID
    if (lotType === "Gated") {
      // Gated Property

      if (spotSize === "Full-Size") {
        // Gated Full-Size
        productID = "price_1NLdwVKUQHJQFuTLMEvIDDMe"; // actual
        // productID = "price_1NMHCIKUQHJQFuTLtenu9LjF"; // test
      } else {
        // Gated Trailer/Bobcat Parking
        productID = "price_1NLe7PKUQHJQFuTLP25P6jIj"; // actual
        // productID = "price_1NMHFXKUQHJQFuTLuE0aDVkp"; // test
      }
    } else if (lotType === "Non-Gated") {
      // Non-Gated Property

      if (spotSize === "Full-Size") {
        // Non-Gated Full-Size
        productID = "price_1NLe6hKUQHJQFuTLD41hfIWV"; // actual
        // productID = "price_1NMjffKUQHJQFuTLQNTMy4HM"; // test
      } else {
        // Non-Gated Trailer/Bobcat Parking
        productID = "price_1NLe7zKUQHJQFuTLacVbKeD1"; // actual
        // productID = "price_1NMHCmKUQHJQFuTLPjOUm70k"; // test
      }
    } else {
      // Error
      setIsModalOpen(true);
      setModalContent({
        title: "Selection Error",
        message: "Please select a lot type before continuing.",
        showSignIn: false,
      });
      return;
    }

    if (spotSize === "Full-Size") {
      // Full-Size
      handlePurchaseNow(productID, false, true, 1);
    } else {
      // Trailer/Bobcat
      handlePurchaseNow(productID, false, false, 1);
    }
  };

  // Checks if there are available parking spots for the requested spot size
  // Spot: true = Full-Size, false = Trailer/Bobcat
  // Returns true if there are available parking spots, false otherwise
  const checkAvailability = async (spot) => {
    const now = new Date();

    // Checking in the 'lotType' collection now, considering 'isFullSize' field
    const parkingSpotRef = collection(db, `lot/${lotType}/parkingSpots`);

    const parkingSpotQuery = query(
      parkingSpotRef,
      where("activeDate", "<", now.toISOString()),
      where("isFullSize", "==", spot)
    );

    const parkingSpotSnapshot = await getDocs(parkingSpotQuery);

    if (parkingSpotSnapshot.empty) {
      // No available parking spots in the selected lot for the requested spot size
      return false;
    }

    // If we reach here, it means there are available parking spots
    return true;
  };

  async function getUserParkingSpot(userPhoneNumber, db) {
    // Define the lot names to check
    const lotNames = ["Gated", "Non-Gated"];
    const now = new Date().toISOString();

    for (let lotName of lotNames) {
      const parkingSpotRef = collection(db, "lot", lotName, "parkingSpots");

      const parkingSpotWithUserQuery = query(
        parkingSpotRef,
        where("user", "==", userPhoneNumber),
        where("endDate", ">=", now)
      );

      const parkingSpotWithUserSnapshot = await getDocs(
        parkingSpotWithUserQuery
      );
      if (!parkingSpotWithUserSnapshot.empty) {
        // User has a valid parking spot in this lot
        const spotData = parkingSpotWithUserSnapshot.docs[0].data();
        return {
          lotType: lotName,
          size: spotData.isFullSize,
          type: spotData.type,
        };
      }
    }

    // No valid parking spots were found for the user
    return null;
  }

  // productID: stripe product ID
  // type: true if daily pass, false if subscription
  // spot: true if spot size is full-size, false if spot size is trailer/bobcat
  // quantity: number of days for daily pass, 1 for subscription
  const handlePurchaseNow = async (productId, type, spot, quantity) => {
    const auth = getAuth();
    const user = auth.currentUser;

    if (!lotType) {
      setIsModalOpen(true);
      setModalContent({
        title: "Selection Error",
        message: "Please select a lot type before continuing.",
        showSignIn: false,
      });
      return;
    }

    if (user) {
      // User is signed in

      // Check if user is an admin
      const userDocRef = doc(db, "users", user.phoneNumber);
      const userDoc = await getDoc(userDocRef);
      if (userDoc.exists()) {
        const userData = userDoc.data();
        if (userData.role === "admin") {
          setModalContent({
            title: "Admin Cannot Purchase",
            message:
              "You are an admin and cannot purchase a subscription or Daily Pass.",
            showSignIn: false,
          });
          setIsModalOpen(true);
          return;
        }
      }

      const userRef = doc(db, "customers", user.uid);
      const subscriptionQuery = query(
        collection(userRef, "subscriptions"),
        where("status", "==", "active")
      );

      const subscriptionSnapshot = await getDocs(subscriptionQuery);
      if (!subscriptionSnapshot.empty) {
        // User already has an active subscription
        if (type) {
          // User is trying to buy a daily pass but already has an active subscription
          setModalContent({
            title: "Subscription Exists",
            message:
              "You already have an active subscription. You cannot purchase a daily pass.",
            showSignIn: false,
          });
          setIsModalOpen(true);
          return;
        }
      }

      const userParkingData = await getUserParkingSpot(user.phoneNumber, db);
      let userParkingType = null;
      if (userParkingData === null && !(await checkAvailability(spot))) {
        // No available parking spots
        setModalContent({
          title: "No Available Parking Spots",
          message:
            "There are no available parking spots of the requested size in the selected lot. Please try another lot/size or try again later.",
          showSignIn: false,
        });
        setIsModalOpen(true);
        return;
      }
      if (userParkingData !== null) {
        userParkingType = userParkingData.type;
      }

      // There are available parking spots, check if the user has a valid daily pass.

      let actualType = "Subscription";

      // User is trying to buy a subscription but already has a valid daily pass
      if (userParkingType !== null && !type) {
        if (type) {
          actualType = "Daily Pass";
        }
        setModalContent({
          // Title should be {userParkingType} already Present
          title: userParkingType + " Already Present",
          message:
            "You already have a valid " +
            userParkingType +
            ". You cannot purchase a " +
            actualType +
            ". Please wait until your Daily Pass expires, or add more days by purchasing another Daily Pass.",
          showSignIn: false,
        });
        setIsModalOpen(true);
        return;
      }

      // If user has a valid daily pass, check if the user is trying to buy the same daily pass.
      // Compare current spot details with selected spot

      if (
        userParkingData !== null &&
        (userParkingData.lotType !== lotType || userParkingData.size !== spot)
      ) {
        const actualSpotSize = userParkingData.size
          ? "Full-Size"
          : "Trailer/Bobcat";
        setModalContent({
          title: "Mismatched Spot Details",
          message: (
            <div>
              <p>
                The selected parking spot details do not match your current
                spot. Please ensure the lot type and size match your current
                spot.
              </p>
              <p>
                Your current lot type is:{" "}
                <strong>{userParkingData.lotType}</strong> and your current spot
                size is: <strong>{actualSpotSize}</strong>.
              </p>
            </div>
          ),
          showSignIn: false,
        });

        setIsModalOpen(true);
        return;
      }

      // Proceed to purchase
      checkOut(productId, user, type, quantity, spot);
    } else {
      // User is not signed in

      if (!(await checkAvailability(spot))) {
        // No available parking spots
        setModalContent({
          title: "No Available Parking Spots",
          message:
            "There are no available parking spots of the requested size in the selected lot. Please try another lot/size or try again later.",
          showSignIn: false,
        });
        setIsModalOpen(true);
        return;
      }

      // There are available parking spots, proceed with the navigation to signup.
      // history('/signup', { state: { productId: productId, quantity: quantity } });
      setModalContent({
        title: "Sign In Required",
        message: "Please Sign In or Sign Up before making a purchase.",
        showSignIn: true,
      });
      setIsModalOpen(true);
    }
  };

  const checkOut = async (productId, user, type, quantity, spot) => {
    let docRef;

    const lotMeta = lotType === "Gated" ? "a" : "b";
    const spotMeta = spot ? "full" : "half";

    if (type) {
      let now = new Date();
      let adjustedDate = new Date(
        now.getTime() + quantity * 24 * 60 * 60 * 1000
      );
      adjustedDate = new Date(
        adjustedDate.getTime() - (adjustedDate.getTime() % (60 * 1000))
      );

      docRef = await addDoc(
        collection(db, "customers", user.uid, "checkout_sessions"),
        {
          // Daily Pass
          price: productId,
          success_url: window.location.origin + "/success",
          cancel_url: window.location.origin,
          mode: "payment",
          // Store the quantity and the correct date in the metadata
          metadata: {
            date: adjustedDate.toISOString(), // Date of expiry of the daily pass
            purchaseDate: now.toISOString(), // Purchase Date
            quantity: quantity,
            lotType: lotMeta,
            spotType: spotMeta,
          },
        }
      );
    } else {
      // Monthly Membership
      docRef = await addDoc(
        collection(db, "customers", user.uid, "checkout_sessions"),
        {
          price: productId,
          success_url: window.location.origin + "/success",
          cancel_url: window.location.origin,
          metadata: {
            parkingSpot: null,
            lotType: lotMeta,
            spotType: spotMeta,
          },
        }
      );
    }

    const unsubscribe = onSnapshot(docRef, async (docSnap) => {
      if (docSnap.exists()) {
        const { error, sessionId } = docSnap.data();

        if (error) {
          alert(`An error occured: ${error.message}`);

          // Navigate to the home page
          history("/");
          return;
        }
        if (sessionId) {
          const stripe = await loadStripe(
            "pk_live_51NAJzFKUQHJQFuTLaTci8gKpMv2tDXcf7iDrPFXn6A7IJCOpPl23hF7pyr7Dwv23DZr4DVequHhkM5jMXWlzrLfD00LcEMOc5d"
          );

          stripe.redirectToCheckout({ sessionId });
        }
      } else {
        // Document does not exist
        console.error("Document does not exist");
      }
    });
  };

  return (
    <div className="bg-white py-24 sm:py-32">
    <div className="mx-auto max-w-7xl px-6 lg:px-8">
      <div className="mx-auto max-w-2xl sm:text-center">
        <h2 className="text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl">
          Secure Truck Parking Made Simple
        </h2>

        {/* Website Description */}
        <p className="mt-6 text-xl leading-8 text-gray-600 font-light">
          Choose between our 1 month truck parking membership or daily parking
          options. Park your truck with confidence knowing that your truck is
          safe and secure. Join us today and experience hassle-free truck
          parking.
        </p>

        {/* Access Account Page/ Open Gate Button (only when logged in) */}
        {isLoggedIn && (
          <button
            onClick={() => history("/account")}
            setIsModalOpen={false}
            className="mt-10 block w-full rounded-md bg-gradient-to-r from-blue-500 to-blue-700 px-6 py-3 text-center text-sm font-semibold text-white shadow-md hover:from-blue-700 hover:to-blue-500 focus:outline-none"
          >
            Access Account Page / Open Gate
          </button>
        )}

        {/* Pill Selector */}
        <div className="mt-10 flex items-center justify-center gap-2">
          <button
            className={`px-6 py-3 rounded-lg text-white transition-all duration-200 ease-in-out shadow-md ${
              lotType === "Gated" ? "bg-gradient-to-r from-purple-500 to-indigo-600" : "bg-gray-300"
            }`}
            onClick={() => handleLotTypeChange("Gated")}
          >
            Gated
          </button>
          <button
            className={`px-6 py-3 rounded-lg text-white transition-all duration-200 ease-in-out shadow-md ${
              lotType === "Non-Gated" ? "bg-gradient-to-r from-purple-500 to-indigo-600" : "bg-gray-300"
            }`}
            onClick={() => handleLotTypeChange("Non-Gated")}
          >
            Non-Gated
          </button>
        </div>

        {/* Address Display */}
        {lotType === "Gated" && (
          <div className="mt-4 text-lg text-gray-600">
            <div className="flex flex-col items-center">
              <h3 className="mb-2 font-semibold text-xl">Address:</h3>
              <button
                className="bg-gradient-to-r from-purple-500 to-indigo-600 text-white px-4 py-2 rounded-md hover:from-indigo-600 hover:to-purple-500 transition-all duration-200 ease-in-out shadow-md focus:outline-none"
                onClick={() => openDirections(gatedAddress)}
              >
                Get Gated Lot Directions
              </button>
            </div>
          </div>
        )}

        {lotType === "Non-Gated" && (
          <div className="mt-4 text-lg text-gray-600">
            <div className="flex flex-col items-center">
              <h3 className="mb-2 font-semibold text-xl">Address:</h3>
              <button
                className="bg-gradient-to-r from-purple-500 to-indigo-600 text-white px-4 py-2 rounded-md hover:from-indigo-600 hover:to-purple-500 transition-all duration-200 ease-in-out shadow-md focus:outline-none"
                onClick={() => openDirections(nonGatedAddress)}
              >
                Get Non-Gated Lot Directions
              </button>
            </div>
          </div>
        )}
      </div>

        {/* Error Notifications */}
        <Modal
          isOpen={isModalOpen}
          onRequestClose={() => setIsModalOpen(false)}
          contentLabel="Notification Modal"
          className="text-center rounded-xl bg-white p-6 ring ring-blue-50 sm:p-8 lg:p-10"
          overlayClassName="modal-overlay"
        >
          <h2 className="text-2xl font-bold text-gray-900 mb-3">
            {modalContent.title}
          </h2>
          <p className="mb-4">{modalContent.message}</p>
          {modalContent.showSignIn && (
            <button
              onClick={() => history("/signup")}
              className="modal-confirm-button bg-blue-500 text-white rounded-lg px-6 py-2 text-lg mb-4 mr-4"
            >
              Sign In/Sign Up
            </button>
          )}
          <button
            onClick={() => setIsModalOpen(false)}
            className="modal-cancel-button bg-red-500 text-white rounded-lg px-6 py-2 text-lg"
          >
            Close
          </button>
        </Modal>

        {/* Membership */}
<div className="mx-auto mt-16 max-w-2xl rounded-3xl ring-1 ring-gray-200 sm:mt-20 lg:mx-0 lg:flex lg:max-w-none shadow-md">
  <div className="p-8 sm:p-10 lg:flex-auto">
    <h3 className="text-2xl font-bold tracking-tight text-blue-900 sm:text-3xl">
      {lotType === "Gated"
        ? "1-Month Gated Membership"
        : "1-Month Non-Gated Membership"}
    </h3>
    <p className="mt-6 text-base leading-7 text-gray-700 sm:text-lg sm:leading-8">
      Experience long-term convenience with our auto-renewing 1-Month
      Membership. Gain access to premium truck parking facilities,
      perks, and peace of mind, ensuring your parking needs are
      consistently met while you focus on the road.
    </p>
    <div className="mt-10 flex items-center gap-x-4">
      <h4 className="flex-none text-sm font-semibold leading-6 text-blue-700 sm:text-lg">
        What’s included
      </h4>
      <div className="h-px flex-auto bg-gray-300" />
    </div>
    <ul className="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6">
      {lotType === "Gated"
        ? gateFeatures.map((feature) => (
            <li key={feature} className="flex gap-x-3">
              <CheckIcon
                className="h-6 w-5 flex-none text-blue-700"
                aria-hidden="true"
              />
              {feature}
            </li>
          ))
        : nonGateFeatures.map((feature) => (
            <li key={feature} className="flex gap-x-3">
              <CheckIcon
                className="h-6 w-5 flex-none text-blue-700"
                aria-hidden="true"
              />
              {feature}
            </li>
          ))}
    </ul>
  </div>
  <div className="-mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0">
    <div className="rounded-2xl bg-gray-100 py-10 text-center ring-1 ring-inset ring-gray-300 lg:flex lg:flex-col lg:justify-center lg:py-16 shadow-md">
      <div className="mx-auto max-w-xs px-8">
        <p className="text-base font-semibold text-blue-600 sm:text-lg">
          {lotType === "Gated"
            ? "1-Month Gated Membership"
            : "1-Month Non-Gated Membership"}
        </p>
        <p className="mt-6 flex items-baseline justify-center gap-x-2">
          <span className="text-5xl font-bold tracking-tight text-gray-900 sm:text-6xl">
            $200
          </span>
          <span className="text-sm font-semibold leading-6 tracking-wide text-gray-600 sm:text-base">
            USD/month
          </span>
        </p>

        {/* Spot Size Selector */}
        <div className="mt-10 flex items-center justify-center">
          <button
            className={`w-32 text-center px-4 py-2 rounded-l-lg text-white whitespace-nowrap ${
              spotSize === "Full-Size" ? "bg-blue-700" : "bg-gray-400"
            }`}
            onClick={() => setSpotSize("Full-Size")}
          >
            Full-Size
          </button>
          <button
            className={`w-32 text-center px-4 py-2 rounded-r-lg text-white whitespace-nowrap ${
              spotSize === "Trailer" ? "bg-blue-700" : "bg-gray-400"
            }`}
            onClick={() => setSpotSize("Trailer")}
          >
            Trailer/Bobcat
          </button>
        </div>

        <button
          href="#"
          className="mt-10 block w-full rounded-md bg-blue-700 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-blue-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-700"
          onClick={() => assignAndHandlePurchaseNow()}
        >
          Purchase Now
        </button>
        <p className="mt-6 text-xs leading-5 text-gray-600 sm:text-sm">
          Your membership will auto-renew at $200/month after 1 month.
          Cancel anytime.
        </p>
      </div>
    </div>
  </div>
</div>


        {/* Daily Pass */}
        <DailyPass handlePurchaseNow={handlePurchaseNow} lotType={lotType} />
      </div>
    </div>
  );
}
