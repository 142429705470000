import { Route, Routes } from 'react-router-dom';
import firebase from './firebase';
import Home from './pages/Home.js';
import Login from './pages/Login.js';
import Account from './pages/AccountPage.js';
import Signup from './pages/Signup.js';
import ErrorPage from './pages/ErrorPage.js';
import Admin from './pages/Admin.js';
import SuccessPage from './pages/SuccessPage.js';

function App() {
  return <Routes>
    <Route path="/" element={<Home />} />
    <Route path="/login" element={<Login />} />
    <Route path="/account" element={<Account />} />
    <Route path="/signup" element={<Signup />} />
    <Route path="/admin" element={<Admin />} />
    <Route path="/success" element={<SuccessPage />} />
    <Route path="*" element={<ErrorPage />} />
  </Routes>;
    
}

export default App;