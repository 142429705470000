import React, { useEffect, useState, useRef } from "react";
import { getAuth } from "firebase/auth";
import {
  doc,
  getDoc,
  collection,
  getDocs,
  updateDoc,
} from "firebase/firestore";
import { getFirestore, query, orderBy } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";
import AccountHeader from "../components/AccountHeader";
import Footer from "../components/Footer";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-calendar/dist/Calendar.css";
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid'


Modal.setAppElement("#root");

export default function Admin() {
  const [parkingSpots, setParkingSpots] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedSpot, setSelectedSpot] = useState(null);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [modalAction, setModalAction] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedLot, setSelectedLot] = useState("");
  const [gateLog, setGateLog] = useState([]);
  const [isGateLogModalOpen, setIsGateLogModalOpen] = useState(false);
  const [userInformation, setUserInformation] = useState({});
  const [selectedDate, setSelectedDate] = useState(new Date());
  const datePickerRef = useRef(null);

  const navigate = useNavigate();

  const handleLotSelection = (event) => {
    setSelectedLot(event.target.value);
  };

  useEffect(() => {
    fetchParkingSpots();
  }, [selectedLot]);

  const getCountSpots = (status) => {
    return parkingSpots.filter(
      (spot) =>
        getParkingSpotStatus(
          spot.activeDate,
          spot.endDate,
          spot.reserved,
          spot.type
        ) === status
    ).length;
  };

  const openGateLogModal = async () => {
    try {
      const db = getFirestore();
      const messageLogRef = collection(db, "messageLog");
      const querySnapshot = await getDocs(
        query(messageLogRef, orderBy("timestamp", "desc"))
      );

      const logMessages = querySnapshot.docs.map((doc) => doc.data());
      setGateLog(logMessages);

      setIsGateLogModalOpen(true);
    } catch (error) {
      console.error("Error retrieving gate log:", error);
    }
  };

  const closeGateLogModal = () => {
    setIsGateLogModalOpen(false);
  };

  const getUserInfo = async (phoneNumber) => {
    try {
      const db = getFirestore();
      const userDocRef = doc(db, "users", phoneNumber);
      const userDocSnapshot = await getDoc(userDocRef);

      if (userDocSnapshot.exists()) {
        const userInfo = userDocSnapshot.data();
        const phoneNumber = userInfo.phoneNumber;
        const name = userInfo.name;
        return { phoneNumber, name };
        // return `${userInfo.name} (${phoneNumber})`;
      } else {
        return "User not found";
      }
    } catch (error) {
      console.error("Error retrieving user information:", error);
      return "Error retrieving information";
    }
  };

  const filterGateLogByDate = (logMessage) => {
    if (!selectedDate) {
      return true; // Show all messages if no date is selected
    }
    const logDate = logMessage.timestamp.toDate();
    return logDate.toDateString() === selectedDate.toDateString();
  };

  const filteredGateLog = gateLog.filter(filterGateLogByDate);

  useEffect(() => {
    const fetchUserInformation = async () => {
      const updatedUserInformation = {};
      for (const logMessage of gateLog) {
        try {
          const userInfo = await getUserInfo(logMessage.user);
          if (userInfo && userInfo.phoneNumber && userInfo.name) {
            updatedUserInformation[logMessage.user] = {
              phoneNumber: userInfo.phoneNumber,
              name: userInfo.name,
            };
          }
        } catch (error) {
          console.error("Error retrieving user information:", error);
        }
      }
      setUserInformation(updatedUserInformation);
    };

    fetchUserInformation();
  }, [gateLog]);

  const handleSpotStatusClick = (status) => {
    if (selectedSpot === status) {
      setSelectedSpot(""); // clear selection if same spot clicked twicee
    } else {
      setSelectedSpot(status); // otherwise update selected spot as before
    }
    setSelectedStatus(selectedStatus === status ? null : status); // Set the new selected status or clear it
  };

  const getOccupancyRate = () => {
    if (parkingSpots.length === 0) {
      return "NaN";
    } else {
      const fullSpots = getCountSpots("red") + getCountSpots("orange");
      const totalSpots = parkingSpots.length;
      return ((fullSpots / totalSpots) * 100).toFixed(2);
    }
  };

  const fetchParkingSpots = async () => {
    const db = getFirestore();
    let parkingSpotsSnapshot;

    if (selectedLot === "gated") {
      const gatedLotRef = collection(db, "lot", "Gated", "parkingSpots");
      parkingSpotsSnapshot = await getDocs(gatedLotRef);
    } else if (selectedLot === "non-gated") {
      const nonGatedLotRef = collection(db, "lot", "Non-Gated", "parkingSpots");
      parkingSpotsSnapshot = await getDocs(nonGatedLotRef);
    } else {
      // No lot selected, clear the parking spots
      setParkingSpots([]);
      return;
    }

    const spots = parkingSpotsSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setParkingSpots(spots);
  };

  const reserveSpot = async () => {
    const db = getFirestore();
    let spotRef;

    if (selectedLot === "gated") {
      spotRef = doc(db, "lot", "Gated", "parkingSpots", selectedSpot.id);
    } else if (selectedLot === "non-gated") {
      spotRef = doc(db, "lot", "Non-Gated", "parkingSpots", selectedSpot.id);
    } else {
      // No lot selected, return or show an error message
      return;
    }

    const now = new Date();
    const futureDate = new Date();
    futureDate.setFullYear(now.getFullYear() + 100);

    await updateDoc(spotRef, {
      endDate: futureDate.toISOString(),
      activeDate: futureDate.toISOString(),
      reserved: true,
    });

    fetchParkingSpots();
    closeModal();
  };

  const openSpot = async () => {
    const db = getFirestore();
    let spotRef;

    if (selectedLot === "gated") {
      spotRef = doc(db, "lot", "Gated", "parkingSpots", selectedSpot.id);
    } else if (selectedLot === "non-gated") {
      spotRef = doc(db, "lot", "Non-Gated", "parkingSpots", selectedSpot.id);
    } else {
      // No lot selected, return or show an error message
      return;
    }

    const now = new Date();

    await updateDoc(spotRef, {
      endDate: now.toISOString(),
      activeDate: now.toISOString(),
      reserved: false,
    });

    fetchParkingSpots();
    closeModal();
  };

  const handleAction = async (action) => {
    setModalAction(action);
    setIsConfirmModalOpen(true);
  };

  const confirmAction = () => {
    if (modalAction === "reserve") {
      reserveSpot();
    } else if (modalAction === "open") {
      openSpot();
    }
    setIsConfirmModalOpen(false);
  };

  const openModal = async (spot) => {
    const selectedSpotCopy = JSON.parse(JSON.stringify(spot));

    // Get user details
    try {
      const db = getFirestore();
      const userDocRef = doc(db, "users", selectedSpotCopy.user);
      const userDocSnapshot = await getDoc(userDocRef);
      if (userDocSnapshot.exists()) {
        selectedSpotCopy.userDetails = userDocSnapshot.data();
      } else {
        selectedSpotCopy.userDetails = "Error retrieving information";
      }
    } catch (error) {
      console.error("Error fetching user details:", error);
      selectedSpotCopy.userDetails = "Error retrieving information";
    }

    setSelectedSpot(selectedSpotCopy);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setIsConfirmModalOpen(false);
    setSelectedSpot(null);
    setModalAction(null);
  };

  const getParkingSpotStatus = (activeDate, endDate, reserved, type) => {
    const now = Date.now();
    const activeDateTime = new Date(activeDate).getTime();
    const endDateTime = new Date(endDate).getTime();

    if (reserved) {
      return "orange";
    } else if (now < activeDateTime && now < endDateTime) {
      return "red";
    } else if (now < activeDateTime && now > endDateTime) {
      return type === "subscription" ? "green" : "yellow";
      // return "yellow";
    } else {
      return "green";
    }
  };

  useEffect(() => {
    fetchParkingSpots();
  }, []);

  useEffect(() => {
    async function fetchUserRole() {
      const db = getFirestore();
      const auth = getAuth();
      const user = auth.currentUser;

      if (user) {
        const userDocRef = doc(db, "users", user.phoneNumber);
        const userDocSnapshot = await getDoc(userDocRef);
        if (userDocSnapshot.exists()) {
          const userData = userDocSnapshot.data();
          if (userData.role !== "admin") {
            navigate("/");
          }
        } else {
          navigate("/");
        }
      } else {
        navigate("/");
      }
    }

    fetchUserRole();
  }, [navigate]);

  // Utility function to format phone number
  const formatPhoneNumber = (phoneNumber) => {
    const cleaned = `${phoneNumber}`.replace(/\D/g, "");
    const match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `+${match[1]} (${match[2]}) ${match[3]}-${match[4]}`;
    }
    return phoneNumber;
  };

  const changeDate = (days) => {
    setSelectedDate(prevDate => {
      const newDate = new Date(prevDate);
      newDate.setDate(newDate.getDate() + days);
      return newDate;
    });
  }
  

  return (
    <div className="App">
      <AccountHeader />
      <div className="main-content mx-auto max-w-2xl px-4 sm:px-6 lg:px-8 mt-8">
        {/* Lot Selector */}
        <div className="flex justify-center items-center mb-6">
          <select
            className="px-4 py-2 border border-gray-300 rounded-md shadow-sm w-48"
            value={selectedLot}
            onChange={handleLotSelection}
          >
            <option value="">Select Lot Type</option>
            <option value="gated">Gated Lot</option>
            <option value="non-gated">Non-Gated Lot</option>
          </select>
          <div className="w-4" />
          <button
            className="px-4 py-2 bg-blue-500 text-white rounded-md"
            onClick={openGateLogModal}
          >
            Open Gate Log
          </button>
        </div>

        {/* Spot Status Summary */}
        <article className="text-center rounded-xl bg-white p-6 ring ring-indigo-50 sm:p-8 lg:p-10 mb-6">
          <h1 className="text-2xl font-bold text-gray-900">Spot Summary</h1>

          <div className="grid grid-cols-2 md:grid-cols-5 gap-4 mt-4">
            <div
              className={`bg-red-200 p-4 rounded-lg ${
                selectedSpot === "red" ? "ring-4 ring-red-500" : ""
              }`}
              onClick={() => handleSpotStatusClick("red")}
            >
              <h2 className="text-lg font-semibold text-red-500">
                Taken Spots
              </h2>
              <p className="text-red-500">{getCountSpots("red")}</p>
            </div>
            <div
              className={`bg-green-200 p-4 rounded-lg ${
                selectedSpot === "green" ? "ring-4 ring-green-500" : ""
              }`}
              onClick={() => handleSpotStatusClick("green")}
            >
              <h2 className="text-lg font-semibold text-green-500">
                Empty Spots
              </h2>
              <p className="text-green-500">{getCountSpots("green")}</p>
            </div>
            <div
              className={`bg-yellow-200 p-4 rounded-lg ${
                selectedSpot === "yellow" ? "ring-4 ring-yellow-500" : ""
              }`}
              onClick={() => handleSpotStatusClick("yellow")}
            >
              <h2 className="text-lg font-semibold text-yellow-500">
                Waiting State
              </h2>
              <p className="text-yellow-500">{getCountSpots("yellow")}</p>
            </div>
            <div
              className={`bg-orange-200 p-4 rounded-lg ${
                selectedSpot === "orange" ? "ring-4 ring-orange-500" : ""
              }`}
              onClick={() => handleSpotStatusClick("orange")}
            >
              <h2 className="text-lg font-semibold text-orange-500">
                Reserved Spots
              </h2>
              <p className="text-orange-500">{getCountSpots("orange")}</p>
            </div>
            <div className="bg-blue-200 p-4 rounded-lg w-auto">
              <h2 className="text-lg font-semibold text-blue-500">
                Occupancy Rate
              </h2>
              <p className="text-blue-500">{getOccupancyRate()}%</p>
            </div>
          </div>
        </article>

        {/* Parking Spot Information */}
        <article className="text-center rounded-xl bg-white p-6 ring ring-indigo-50 sm:p-8 lg:p-10 mb-10">
          <h1 className="text-2xl font-bold text-gray-900">Parking Spots</h1>
          {(() => {
            let filteredParkingSpots = parkingSpots;
            if (selectedStatus) {
              filteredParkingSpots = parkingSpots.filter(
                (spot) =>
                  getParkingSpotStatus(
                    spot.activeDate,
                    spot.endDate,
                    spot.reserved,
                    spot.type
                  ) === selectedStatus
              );
            }
            return filteredParkingSpots.map((spot) => (
              <div
                key={spot.id}
                className={`border-2 border-indigo-500 rounded-lg p-4 m-4 hover:shadow-lg cursor-pointer ${
                  getParkingSpotStatus(
                    spot.activeDate,
                    spot.endDate,
                    spot.reserved,
                    spot.type
                  ) === "red"
                    ? "bg-red-200"
                    : getParkingSpotStatus(
                        spot.activeDate,
                        spot.endDate,
                        spot.reserved,
                        spot.type
                      ) === "yellow"
                    ? "bg-yellow-200"
                    : getParkingSpotStatus(
                        spot.activeDate,
                        spot.endDate,
                        spot.reserved,
                        spot.type
                      ) === "orange"
                    ? "bg-orange-200"
                    : "bg-green-200"
                }`}
                onClick={() => openModal(spot)}
              >
                <h2 className="text-lg font-semibold text-indigo-900 mb-2">
                  Parking Spot: {spot.parkingNum}
                </h2>
              </div>
            ));
          })()}

          {/* Spot Detail Modal */}
          <Modal
            isOpen={isModalOpen}
            onRequestClose={closeModal}
            contentLabel="Spot Detail"
            className="flex flex-col items-center justify-center max-w-full mx-auto rounded-sm bg-white p-4 ring ring-blue-50 sm:p-6 overflow-y-auto"
            overlayClassName="modal-overlay"
            style={{
              content: {
                position: "absolute",
                top: "50%",
                left: "50%",
                right: "auto",
                bottom: "auto",
                marginRight: "-50%",
                transform: "translate(-50%, -50%)",
                maxWidth: "90%",
                maxHeight: "90%",
                overflow: "scroll",
              },
            }}
          >
            {selectedSpot && (
              <div className="modal-inner space-y-2 w-full">
                <h1 className="text-lg md:text-lg font-semibold text-gray-800 mb-2 text-center">
                  Spot {selectedSpot.parkingNum} |{" "}
                  {selectedSpot.isFullSize === true
                    ? "Full-Size"
                    : "Trailer/Bobcat"}
                </h1>
                <div className="user-info mb-2 bg-yellow-50 p-2 rounded-sm">
                  <h2 className="text-sm md:text-lg font-bold text-yellow-600 text-center mb-2">
                    Spot Information
                  </h2>
                  <div className="grid grid-cols-2 gap-2 text-gray-800">
                    <p className="modal-detail">
                      <strong>Spot Number:</strong> {selectedSpot.parkingNum}
                    </p>
                    <p className="modal-detail">
                      <strong>Size:</strong>{" "}
                      {selectedSpot.isFullSize === true
                        ? "Full-Size"
                        : "Trailer/Bobcat"}
                    </p>
                    <p className="modal-detail">
                      <strong>End Date:</strong>{" "}
                      {new Date(selectedSpot.endDate).toLocaleString()}
                    </p>
                    <p className="modal-detail">
                      <strong>Active Date:</strong>{" "}
                      {new Date(selectedSpot.activeDate).toLocaleString()}
                    </p>
                    <p className="modal-detail">
                      <strong>Type:</strong> {selectedSpot.type}
                    </p>
                  </div>
                </div>
                <div className="user-info mb-2 bg-blue-50 p-2 rounded-sm">
                  <h2 className="text-sm md:text-lg font-bold text-blue-600 text-center mb-2">
                    User Information
                  </h2>
                  {selectedSpot.userDetails &&
                  typeof selectedSpot.userDetails === "object" ? (
                    <div className="grid grid-cols-2 gap-2 text-gray-800">
                      <p className="modal-detail">
                        <strong>Name:</strong> {selectedSpot.userDetails.name}
                      </p>
                      <p className="modal-detail">
                        <strong>Email:</strong> {selectedSpot.userDetails.email}
                      </p>
                      <p className="modal-detail">
                        <strong>Phone:</strong>{" "}
                        {selectedSpot.userDetails.phoneNumber}
                      </p>
                      <p className="modal-detail">
                        <strong>Truck:</strong>{" "}
                        {selectedSpot.userDetails.truckColor},{" "}
                        {selectedSpot.userDetails.truckNumber}
                      </p>
                    </div>
                  ) : (
                    <p className="modal-detail text-red-600">
                      Error retrieving information
                    </p>
                  )}
                </div>
                <div className="actions flex flex-col md:flex-row justify-between">
                  <button
                    onClick={closeModal}
                    className="modal-close-button bg-blue-500 text-white rounded-sm px-4 py-1 text-sm md:text-lg mr-2"
                  >
                    Close
                  </button>
                  <div>
                    <button
                      onClick={() => handleAction("reserve")}
                      className="modal-reserve-button bg-red-500 text-white rounded-sm px-4 py-1 text-sm md:text-lg mr-2"
                    >
                      Reserve
                    </button>
                    <button
                      onClick={() => handleAction("open")}
                      className="modal-open-button bg-green-500 text-white rounded-sm px-4 py-1 text-sm md:text-lg"
                    >
                      Open
                    </button>
                  </div>
                </div>
              </div>
            )}
          </Modal>

          {/* Confirm Modal */}
          <Modal
            isOpen={isConfirmModalOpen}
            onRequestClose={() => setIsConfirmModalOpen(false)}
            contentLabel="Confirm Action"
            className="text-center rounded-xl bg-white p-6 ring ring-blue-50 sm:p-8 lg:p-10"
            overlayClassName="modal-overlay"
          >
            <h2 className="text-2xl font-bold text-gray-900 mb-3">
              Are you sure you want to {modalAction} this spot?
            </h2>
            <button
              onClick={confirmAction}
              className="modal-confirm-button bg-blue-500 text-white rounded-lg px-6 py-2 text-lg mb-4 mr-4"
            >
              Yes
            </button>
            <button
              onClick={() => setIsConfirmModalOpen(false)}
              className="modal-cancel-button bg-red-500 text-white rounded-lg px-6 py-2 text-lg"
            >
              No
            </button>
          </Modal>

          {/* Gate Log Modal */}
          <Modal
            isOpen={isGateLogModalOpen}
            onRequestClose={closeGateLogModal}
            contentLabel="Gate Log"
            className="gate-log-modal"
            overlayClassName="gate-log-modal-overlay"
          >
            <h2 className="text-xl font-bold mb-4 mt-10">Gate Log</h2>
            <div className="modal-content">
              <div className="date-picker-container mb-4">
              <button onClick={() => changeDate(-1)}>
    <ChevronLeftIcon className="h-5 w-5" />
  </button>
                <DatePicker
                  selected={selectedDate}
                  onChange={setSelectedDate}
                  dateFormat="MM/dd/yyyy"
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  customInput={
                    <button
                      className="date-picker-button"
                      onClick={() => datePickerRef.current.setOpen(true)}
                      style={{
                        padding: "0.5rem",
                        border: "1px solid #ccc",
                        borderRadius: "4px",
                        cursor: "pointer",
                        backgroundColor: "#fff",
                        color: "#333",
                        fontSize: "14px",
                        fontWeight: "bold",
                        width: "150px",
                      }}
                    >
                      {selectedDate.toLocaleDateString("en-US")}
                    </button>
                  }
                  ref={datePickerRef}
                />
                <button onClick={() => changeDate(1)}>
    <ChevronRightIcon className="h-5 w-5" />
  </button>
              </div>
              {filteredGateLog.map((logMessage, index) => {
                const userInfo = userInformation[logMessage.user];
                return (
                  <div key={index} className="log-message mb-4">
                    <p className="timestamp">
                      {logMessage.timestamp.toDate().toLocaleString()}
                    </p>
                    {userInfo && <p className="name">{userInfo.name}</p>}
                    <p className="user">
                      Phone: {formatPhoneNumber(logMessage.user)}
                    </p>
                  </div>
                );
              })}
            </div>
            <button
              className="modal-close-button-gate bg-blue-500 text-white rounded-lg px-6 py-2 mt-4 mb-10"
              onClick={closeGateLogModal}
            >
              Close
            </button>
          </Modal>
        </article>
      </div>
      <Footer />
    </div>
  );
}
