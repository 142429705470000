import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import {
  getAuth,
  signInWithPhoneNumber,
  RecaptchaVerifier,
  PhoneAuthProvider,
  signInWithCredential,
} from "firebase/auth";
import {
  getFirestore,
  doc,
  getDoc,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import "../App.css";

export default function Signup() {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [verificationId, setVerificationId] = useState("");
  const [otp, setOtp] = useState("");
  const [showOTPField, setShowOTPField] = useState(false);
  const [showSignupForm, setShowSignupForm] = useState(false);
  const [recaptchaVerifier, setRecaptchaVerifier] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [truckNumber, setTruckNumber] = useState("");
  const [truckColor, setTruckColor] = useState("");
  const db = getFirestore();
  const navigate = useNavigate();

  useEffect(() => {
    const auth = getAuth();

    const verifier = new RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible",
      },
      auth
    );
    setRecaptchaVerifier(verifier);

    console.log("here3");
  }, []);

  const handleSignUp = async (e) => {
    e.preventDefault();

    const phoneNumberFormatted = `+${phoneNumber}`;

    const docRef = doc(db, "users", phoneNumberFormatted);
    const docSnap = await getDoc(docRef);

    if (!docSnap.exists()) {
      // User does not exist
      setShowSignupForm(true);
    } else {
      // User exists, sign them in
      signInWithPhoneNumber(getAuth(), phoneNumberFormatted, recaptchaVerifier)
        .then((confirmationResult) => {
          setVerificationId(confirmationResult.verificationId);
          setShowOTPField(true);
          setErrorMessage("");
        })
        .catch((error) => {
          console.error("Phone verification error:", error);
          setErrorMessage("Invalid Phone Number. Please try again.");
        });
    }
  };

  const handleSignupFormSubmit = async (e) => {
    e.preventDefault();

    // If the fields are empty, show error message
    if (!email || !name || !truckNumber || !truckColor) {
      setErrorMessage("Please fill out all fields.");
      return;
    }

    const phoneNumberFormatted = `+${phoneNumber}`;

    await setDoc(doc(db, "users", phoneNumberFormatted), {
      phoneNumber: phoneNumberFormatted,
      email: email,
      name: name,
      truckNumber: truckNumber,
      truckColor: truckColor,
    });

    // Show OTP field after successful signup
    signInWithPhoneNumber(getAuth(), phoneNumberFormatted, recaptchaVerifier)
      .then((confirmationResult) => {
        setVerificationId(confirmationResult.verificationId);
        setShowOTPField(true);
        setErrorMessage("");
        setShowSignupForm(false);
      })
      .catch((error) => {
        console.error("Phone verification error:", error);
        setErrorMessage("Invalid Phone Number. Please try again.");
      });
  };

  const handleOTPVerification = (e) => {
    e.preventDefault();

    const auth = getAuth();
    const credential = PhoneAuthProvider.credential(verificationId, otp);
    signInWithCredential(auth, credential)
      .then(async (userCredential) => {
        // Handle successful OTP verification

        const { user } = userCredential;

        const phoneNumberFormatted = `+${phoneNumber}`;

        const userDoc = doc(db, "users", phoneNumberFormatted);

        await updateDoc(userDoc, {
          uid: user.uid,
        });

        navigate("/account");
      })
      .catch((error) => {
        console.error("OTP verification error:", error);
        setErrorMessage("OTP verification error. Please try again.");
      });
  };

  return (
    <div className="App">
      <Header />

      <div className="main-content">
        <div className="flex flex-col items-center min-h-screen px-6 py-12 lg:px-8">
          <div className="sm:w-full sm:max-w-sm">
            <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
              {/* Sign up for an account */}
            </h2>
          </div>

          <div id="recaptcha-container"></div>

          {errorMessage && (
            <div
              class="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative padding-top:100px"
              role="alert"
            >
              <strong class="font-bold">Error:</strong>
              <span class="block sm:inline"> {errorMessage} </span>
            </div>
          )}

          <div className="mt-10 sm:w-full sm:max-w-sm">
            {!showSignupForm && !showOTPField ? (
              <form className="space-y-6" onSubmit={handleSignUp}>
                <div>
                  <h1 className="text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                    Enter your phone number.
                  </h1>

                  <label
                    htmlFor="phone"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Phone number
                  </label>
                  <div className="mt-2">
                    <PhoneInput
                      country={"us"}
                      value={phoneNumber}
                      onChange={(phone) => setPhoneNumber(phone)}
                      inputStyle={{ paddingLeft: "50px" }}
                      inputProps={{
                        id: "phone",
                        name: "phone",
                        required: true,
                        autoComplete: "tel",
                        className:
                          "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6",
                      }}
                    />
                  </div>

                  <p className="mt-2 text-xs text-gray-500">
                    {" "}
                    SMS message for verification and standard rates may apply.{" "}
                  </p>
                </div>

                <div>
                  <button
                    type="submit"
                    className="flex w-full justify-center rounded-md bg-gray-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    Continue
                  </button>
                </div>
              </form>
            ) : null}

            {showSignupForm ? (
              <form className="space-y-6" onSubmit={handleSignupFormSubmit}>
                <h1 className="text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                  Sign up for an account
                </h1>
                {/* Add more fields as required */}
                <input
                  type="text"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
                <input
                  type="text"
                  placeholder="Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
                <input
                  type="text"
                  placeholder="Truck Number"
                  value={truckNumber}
                  onChange={(e) => setTruckNumber(e.target.value)}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
                <input
                  type="text"
                  placeholder="Truck Color"
                  value={truckColor}
                  onChange={(e) => setTruckColor(e.target.value)}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
                <button
                  type="submit"
                  className="flex w-full justify-center rounded-md bg-gray-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Submit
                </button>
              </form>
            ) : null}

            {showOTPField ? (
              <div>
                <h1 className="text-center font-bold leading-9 tracking-tight text-gray-900">
                  Your One Time Password has been sent to your mobile phone.
                  Please enter the number below.
                </h1>

                <form className="space-y-6" onSubmit={handleOTPVerification}>
                  <input
                    type="text"
                    placeholder="Enter OTP"
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />

                  <p className="mt-2 text-xs text-gray-500">
                    {" "}
                    SMS message for verification and standard rates may apply.{" "}
                  </p>

                  <button
                    type="submit"
                    className="flex w-full justify-center rounded-md bg-gray-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    Verify OTP
                  </button>
                </form>
              </div>
            ) : null}
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}
