import React, { useState, useEffect } from "react";
import { CheckIcon } from "@heroicons/react/solid";
import Modal from "react-modal";

const gateFeatures = [
  "Private gate access",
  "Private parking spot",
  "24/7 camera security",
  "Online account access",
];

const nonGateFeatures = [
  "Private parking spot",
  "24/7 camera security",
  "Online account access",
];

function DailyPass({ handlePurchaseNow, lotType }) {
  const [selectedDays, setSelectedDays] = useState("Select number of days");
  const [price, setPrice] = useState(15);
  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState("");
  const [spotSize, setSpotSize] = useState(null);

  useEffect(() => {
    if (selectedDays === "Select number of days") {
      setPrice(15);
    } else {
      const days = parseInt(selectedDays.split(" ")[0], 10); // get number from string
      setPrice(15 * days);
    }
  }, [selectedDays]);

  const handleClickPurchase = () => {
    if (selectedDays === "Select number of days") {
      setModalTitle("Invalid Date");
      setModalContent("Please select number of days");
      setShowModal(true);
      return; // Do nothing if no days are selected
    }

    if (!spotSize) {
      setModalTitle("Invalid Spot Size");
      setModalContent("Please select a spot size");
      setShowModal(true);
      return; // Do nothing if no days are selected
    }

    let purchaseIdArray = null;
    let spotType = false;
    console.log("LotType: " + lotType);
    if (lotType === "Gated") {
      if (spotSize === "Full-Size") {
        // @TODO Gated Full-Size
        //                                      15                                30                                45                                60                                75                                90                                105
        purchaseIdArray = ['price_1NLxYbKUQHJQFuTLwfp8ubXA', 'price_1NLxYbKUQHJQFuTLY00WpEbf', 'price_1NLxYbKUQHJQFuTLKaZkFXYm', 'price_1NLxYbKUQHJQFuTLg0IPEDm9', 'price_1NLxYbKUQHJQFuTL8KhAM6Bb', 'price_1NLxYbKUQHJQFuTLM6KNPJoe', 'price_1NLxYbKUQHJQFuTLvn1jXPBh'];
        // purchaseIdArray = ["price_1NMHJRKUQHJQFuTL8SvsLiUm", "price_1NMHJRKUQHJQFuTLogXMJeRj", "price_1NMHJRKUQHJQFuTLUkUxAVRZ"];
        spotType = true;
      } else {
        // @TODO Gated Trailer/Bobcat
        //                                      15                                30                                45                                60                                75                                90                                105
        purchaseIdArray = ['price_1NLxvVKUQHJQFuTLys5pkafP', 'price_1NLxvVKUQHJQFuTLIj9DYuvM', 'price_1NLxvVKUQHJQFuTLmTHPXPjT', 'price_1NLxvVKUQHJQFuTL3Q43SYXu', 'price_1NLxvVKUQHJQFuTLcDNvd8a5', 'price_1NLxvVKUQHJQFuTLl91OPJGX', 'price_1NLxvVKUQHJQFuTL30mxd6w2'];
        // purchaseIdArray = [
        //   "price_1NMHMfKUQHJQFuTL4qjfhFUX",
        //   "price_1NMHMfKUQHJQFuTL4Nd3r1qU",
        //   "price_1NMHMfKUQHJQFuTLe9cz7DIT",
        // ];
      }
      const quantity = parseInt(selectedDays.split(" ")[0], 10); // get number from string
      handlePurchaseNow(
        purchaseIdArray[quantity - 1],
        true,
        spotType,
        quantity
      );
    } else {
      if (spotSize === "Full-Size") {
        // @TODO Non-Gated Full-Size
        //                                      15                                30                                45                                60                                75                                90                                105
        purchaseIdArray =  ['price_1NLy2AKUQHJQFuTLVn3GBlWG', 'price_1NLy2AKUQHJQFuTLBEvgz0ym', 'price_1NLy2AKUQHJQFuTL9d1zKTyi', 'price_1NLy2AKUQHJQFuTLUcu49sl0', 'price_1NLy2AKUQHJQFuTLrJkewMD1', 'price_1NLy2AKUQHJQFuTL6px8o9G6', 'price_1NLy2BKUQHJQFuTLwipvpJwB'];
        // purchaseIdArray = [
        //   "price_1NMHKTKUQHJQFuTL92Pl58dU",
        //   "price_1NMHKTKUQHJQFuTL2Rgu3pft",
        //   "price_1NMHKTKUQHJQFuTLTgor9IC8",
        // ];
        spotType = true;
      } else {
        // @TODO Non-Gated Trailer/Bobcat
        //                                      15                                30                                45                                60                                75                                90                                105
        purchaseIdArray = ['price_1NLxoxKUQHJQFuTLSjsS3nwP', 'price_1NLxoxKUQHJQFuTLDl3LM6iR', 'price_1NLxoxKUQHJQFuTLMwrMojVt', 'price_1NLxoxKUQHJQFuTLi9r87pkf', 'price_1NLxoxKUQHJQFuTL6Nom6Ma2', 'price_1NLxoxKUQHJQFuTLYHHerBWs', 'price_1NLxoxKUQHJQFuTL2WH6YYGP'];
        // purchaseIdArray = [
        //   "price_1NMHNRKUQHJQFuTLawGbcFx5",
        //   "price_1NMHNRKUQHJQFuTLXVDzkGy4",
        //   "price_1NMHNRKUQHJQFuTLDVN1Tlr3",
        // ];
        // console.log("Non-Gated Trailer/Bobcat");
      }
      const quantity = parseInt(selectedDays.split(" ")[0], 10); // get number from string
      handlePurchaseNow(
        purchaseIdArray[quantity - 1],
        true,
        spotType,
        quantity
      );
    }
  };

  return (
    <div className="mx-auto mt-16 max-w-2xl rounded-3xl ring-1 ring-gray-200 sm:mt-20 lg:mx-0 lg:flex lg:max-w-none">
      <Modal
        isOpen={showModal}
        onRequestClose={() => setShowModal(false)}
        contentLabel="Notification Modal"
        className="text-center rounded-xl bg-white p-6 ring ring-blue-50 sm:p-8 lg:p-10"
        overlayClassName="modal-overlay"
      >
        <h2 className="text-2xl font-bold text-gray-900 mb-3">{modalTitle}</h2>
        <p className="mb-4">{modalContent}</p>
        <button
          onClick={() => setShowModal(false)}
          className="modal-cancel-button bg-red-500 text-white rounded-lg px-6 py-2 text-lg"
        >
          Close
        </button>
      </Modal>

      <div className="p-8 sm:p-10 lg:flex-auto">
  <h3 className="text-2xl font-bold tracking-tight text-gray-900 text-purple-900 sm:text-3xl">
    {lotType === "Gated" ? "Gated Daily Pass" : "Non-Gated Daily Pass"}
  </h3>
  <p className="mt-6 text-base leading-7 text-gray-700 sm:text-lg sm:leading-8">
    Experience convenience and flexibility with our Daily Pass. Whether
    you're a truck driver on a short trip or in need of temporary parking,
    our Daily Pass offers you a hassle-free solution.
  </p>
  <div className="mt-10 flex items-center gap-x-4">
    <h4 className="flex-none text-sm font-semibold leading-6 text-purple-700 sm:text-lg">
      What’s included
    </h4>
    <div className="h-px flex-auto bg-gray-300" />
  </div>
  <ul className="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6">
    {lotType === "Gated"
      ? gateFeatures.map((feature) => (
          <li key={feature} className="flex gap-x-3">
            <CheckIcon
              className="h-6 w-5 flex-none text-purple-700"
              aria-hidden="true"
            />
            {feature}
          </li>
        ))
      : nonGateFeatures.map((feature) => (
          <li key={feature} className="flex gap-x-3">
            <CheckIcon
              className="h-6 w-5 flex-none text-purple-700"
              aria-hidden="true"
            />
            {feature}
          </li>
        ))}
  </ul>
</div>
<div className="-mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0">
  <div className="rounded-2xl bg-gray-100 py-10 text-center ring-1 ring-inset ring-gray-300 lg:flex lg:flex-col lg:justify-center lg:py-16 shadow-md">
    <div className="mx-auto max-w-xs px-8">
      <p className="text-base font-semibold text-purple-600 sm:text-lg">
        {lotType === "Gated"
          ? "Gated Daily Pass"
          : "Non-Gated Daily Pass"}
      </p>
      <p className="mt-6 flex items-baseline justify-center gap-x-2">
        <span className="text-5xl font-bold tracking-tight text-gray-900 sm:text-6xl">
          ${price}
        </span>
        <span className="text-sm font-semibold leading-6 tracking-wide text-gray-600 sm:text-base">
          {selectedDays === "Select number of days"
            ? "USD/day"
            : "USD total"}
        </span>
      </p>
      <label htmlFor="days" className="sr-only">
        Number of days
      </label>
      <select
        id="days"
        className="mt-8 w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-purple-700 focus:border-purple-700"
        value={selectedDays}
        onChange={(e) => setSelectedDays(e.target.value)}
      >
        <option value="Select number of days">
          Select number of days
        </option>
        {[
          "1 day",
          "2 days",
          "3 days",
          "4 days",
          "5 days",
          "6 days",
          "7 days",
        ].map((day) => (
          <option key={day} value={day}>
            {day}
          </option>
        ))}
      </select>

      {/* Spot Size Selector */}
      <div className="mt-10 flex items-center justify-center">
        <button
          className={`w-32 text-center px-4 py-2 rounded-l-lg text-white whitespace-nowrap ${
            spotSize === "Full-Size" ? "bg-purple-700" : "bg-gray-400"
          }`}
          onClick={() => setSpotSize("Full-Size")}
        >
          Full-Size
        </button>
        <button
          className={`w-32 text-center px-4 py-2 rounded-r-lg text-white whitespace-nowrap ${
            spotSize === "Trailer" ? "bg-purple-700" : "bg-gray-400"
          }`}
          onClick={() => setSpotSize("Trailer")}
        >
          Trailer/Bobcat
        </button>
      </div>
      <button
        href="checkout"
        className="mt-10 block w-full rounded-md bg-purple-700 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-purple-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-purple-700"
        onClick={handleClickPurchase}
      >
        Purchase Now
      </button>
      <p className="mt-6 text-xs leading-5 text-gray-600 sm:text-sm">
        Daily parking is a one-time purchase and does not auto-renew.
      </p>
    </div>
  </div>
</div>


    </div>
  );
}

export default DailyPass;
